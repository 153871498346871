<template>
  <div class="page-NominacionCreate hold-transition">
    <loading v-if="loading" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Nominaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Hidrocarburos</router-link>
                  </li>
                  <li class="breadcrumb-item active">Nominaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <form @submit="saveNominacion" onsubmit="return false">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="">Fecha inicial</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="fecha_inicial"
                          :class="
                            $v.fecha_inicial.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="">Fecha final</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="fecha_final"
                          @blur="calculoNomi()"
                          :class="
                            $v.fecha_final.$invalid ? 'is-invalid' : 'is-valid'
                          "
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="">Nombre</label>
                        <input
                          type="text"
                          v-model="nombre"
                          class="form-control form-control-sm"
                          :class="
                            $v.nombre.$invalid ? 'is-invalid' : 'is-valid'
                          "
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="">Planeaciones</label>

                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="planeacion"
                          label="nombre"
                          :options="planeacionesOption"
                          placeholder="Planeacion"
                        >
                          <template #search="{ attributes, events }">
                            <input
                              class="vs__search"
                              :required="!planeacion"
                              v-bind="attributes"
                              v-on="events"
                            />
                          </template>
                        </v-select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="">Calificacion</label>

                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="calificacion"
                          label="nombre"
                          :options="calificacionesOption"
                          placeholder="Calificacion"
                        >
                          <template #search="{ attributes, events }">
                            <input
                              class="vs__search"
                              :required="!calificacion"
                              v-bind="attributes"
                              v-on="events"
                            />
                          </template>
                        </v-select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="">Estado</label>
                        <select
                          v-model="estado"
                          class="form-control form-control-sm"
                          required
                        >
                          <option
                            v-for="r in listas"
                            v-bind:key="r.id"
                            v-bind:value="r.numeracion"
                          >
                            {{ r.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="">Tipos Productos</label>
                        <select
                          v-model="tipo_producto"
                          class="form-control form-control-sm"
                          required
                        >
                          <option
                            v-for="r in tipos_productoOption"
                            v-bind:key="r.id"
                            v-bind:value="r.numeracion"
                          >
                            {{ r.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Observación</label>
                        <textarea
                          v-model="observacion"
                          class="form-control form-control-sm"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <router-link to="/Hidrocarburos/Nominaciones">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        @click="newItem = true"
                        v-if="
                          $store.getters.can('hidrocarburos.nominaciones.index')
                        "
                      >
                        <i class="fas fa-reply"></i><br />Volver
                      </button>
                    </router-link>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.nominaciones.create'
                        ) ||
                          $store.getters.can('hidrocarburos.nominaciones.edit')
                      "
                    >
                      <i class="fas fa-paper-plane"></i><br />Guardar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import axios from "axios";
import vSelect from "vue-select";

export default {
  name: "NominacionCreate",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      fecha_inicial: "",
      fecha_final: "",
      nombre: "",
      estado: 1,
      tipo_producto: "",
      observacion: "",
      idNominacion: "",
      planeacion: "",
      planeacionesOption: [],
      tipos_productoOption: [],
      modal: {
        title: "",
        accion: "",
      },
      arrListasForm: {
        estados: [],
      },
      objLista: {},
      calificacion: "",
      calificacionesOption: [],
      empresa: "",
      diasNomi: "",
    };
  },
  beforeMount() {
    this.actGetEmpresas();
    this.actGetListas(28);
    this.getTiposProducto();
    this.getPlaneaciones();
    this.getCalificaciones();
  },
  validations: {
    fecha_inicial: {
      required,
      minValue: (value) => value > new Date().toISOString(),
    },
    fecha_final: {
      required,
      minValue: (value) => value > new Date().toISOString(),
    },
    nombre: {
      required,
    },
  },
  computed: {
    ...mapState("modOperacion", ["listas"]),
    ...mapState("modHidrocarburos", ["loading", "empresas"]),
  },
  methods: {
    ...mapActions("modOperacion", ["actGetListas"]),
    ...mapActions("modHidrocarburos", [
      "actSaveNominacion",
      "actSaveNominacionDet",
      "actGetEmpresas",
    ]),

    calculoNomi() {
      this.diasNomi =
        this.fecha_final.slice(8, 10) - this.fecha_inicial.slice(8, 10) + 1;
    },

    saveNominacion() {
      let data = [];
      data = {
        fecha_inicial: this.fecha_inicial,
        fecha_final: this.fecha_final,
        nombre: this.nombre,
        observacion: this.observacion,
        estado: this.estado,
        planeacion_id: this.planeacion.id,
        calificacion_id: this.calificacion.id,
        tipo_producto: this.tipo_producto,
        dias_nomi: this.diasNomi,
      };
      //alert(JSON.stringify(data));
      this.actSaveNominacion(data).then((response) => {
        if (response.id) {
          this.$router.push({
            path: "/Hidrocarburos/NominacionesEdit/" + response.id,
          });
          this.$swal({
            icon: "success",
            title: "La nominación base se creó con exito",
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
        }
      });
    },

    getPlaneaciones() {
      axios.get("api/hidrocarburos/planeaciones/list").then((response) => {
        this.planeacionesOption = response.data;
      });
    },
    getTiposProducto() {
      axios.get("/api/lista/83").then((response) => {
        this.tipos_productoOption = response.data;
      });
    },
    getCalificaciones() {
      axios.get("api/hidrocarburos/calificaciones/lista").then((response) => {
        this.calificacionesOption = response.data;
      });
    },
  },
};
</script>
